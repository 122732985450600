
.education-wrapper {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .education-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-breadcrumb {
            line-height: 40px;
        }
    }
}
.el-link + .el-link {
    margin-left: 10px;
}
.school-logo {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.logo-uploader {
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    ::v-deep .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo-cover {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
    .logo-uploader-icon {
        color: #ccc;
        font-size: 24px;
    }
}
.school-prompt {
    color: #F56C6C;
}
.initialization-dialog {
    ::v-deep .el-dialog__header {
        background-color: #0200D8;
        .el-dialog__title {
            color: #fff;
            font-size: 16px;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }
    }
    ::v-deep .el-dialog__body {
        padding-bottom: 0;
    }
}
